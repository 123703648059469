<template>
  <transition :name="animate ? 'fade' : 'instant'">
    <v-row
      justify="center"
      align="center"
      class="cw-form-loader"
    >
      <div class="progress-container-inner">
        <v-progress-circular
          :size="30"
          :width="2"
          indeterminate
          color="amber darken-2"
        />
      </div>
    </v-row>
  </transition>
</template>

<script>
export default {
  name: 'CwFormLoader',
  props: {
    animate: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.cw-form-loader {
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.instant-leave-active {
  transition: all 0s linear;
}

.instant-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 0.3s linear;

  .progress-container-inner {
    transform: scale(1);
    transition: all 0.3s cubic-bezier(0.75, 0, 0.25, 1);
  }
}

.fade-leave-to {
  opacity: 0;

  .progress-container-inner {
    transform: scale(0);
  }
}
</style>
